import React from "react"
import { Table } from "react-bootstrap"

import { Jumbo } from "../components/jumbotron"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Pricelist = () => (
  <Layout>
    <SEO title="Cenník" />
    <Jumbo title="Cenník"></Jumbo>
    {/* <div className="pricelist__sales d-flex flex-column justify-content-center">
      <h5>Špeciálna akcia!</h5>
      <p>Dentálna hygiena +  Air Flow <s>45€</s><strong>40€</strong></p>
      <p>Extrakcia jednokoreňového zuba <s>30€</s><strong>25€</strong></p>
      <p>Extrakcia viackoreňového zuba <s>40€</s><strong>35€</strong></p>
      <p>Korunka zirkón <s>270€ </s><strong>260€</strong></p>
      <p>Korunka keramika / medzičlen <s>190€</s><strong>180€</strong></p>
      <p>Fazeta celokeramická <s>300€</s><strong>280€</strong></p>
    </div> */}
    <div className="pricelist__info container text-center d-flex flex-column justify-content-center">
      <p>
        Cenník je platný pre pacientov s absolvovanou preventívnou
        stomatologickou prehliadkou v predchádzajúcom roku. Cenník pre
        nezazmluvnených pacientov a bez preventívnej prehliadky je k
        nahliadnutiu v ambulancii.
      </p>
    </div>
    <Table striped bordered responsive="sm" className="container pricelist">
      <thead>
        <tr>
          <th>Konzervačná stomatológia</th>
          <th>Doplatok pre poistencov</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Vstupné komplexné stomatologické vyšetrenie</td>
          <td>25€</td>
        </tr>
        <tr>
          <td>Preventívna stomatologická prehliadka</td>
          <td></td>
        </tr>
        <tr>
          <td>Preventívna pedostomatologická prehliadka</td>
          <td></td>
        </tr>
        <tr>
          <td>Administratívny výkon/napr.výmenný lístok/ vydaný opätovne</td>
          <td>3€</td>
        </tr>
        <tr>
          <td>Vyšetrenie fókusov</td>
          <td>15€</td>
        </tr>
        <tr>
          <td>Vyšetrenie vitality zubov</td>
          <td>8€</td>
        </tr>
        <tr>
          <td>Povrchová slizničná anestézia</td>
          <td>8€</td>
        </tr>
        <tr>
          <td>Injekčná- infiltračná / zvodová anestézia</td>
          <td>11€</td>
        </tr>
        <tr>
          <td>Intraligamentárna anestézia</td>
          <td>14€</td>
        </tr>
        <tr>
          <td>Intraorálny RTG snímok, BTW</td>
          <td>14€</td>
        </tr>
        <tr>
          <td>Pečatenie fisúr /1 zub</td>
          <td>18€</td>
        </tr>
        <tr>
          <td>Provizórna dočasná výplň/ podložka</td>
          <td>18€</td>
        </tr>
        <tr>
          <td>SIC výplň 1 plôška</td>
          <td>35€</td>
        </tr>
        <tr>
          <td>SIC výplň 2 plôšky</td>
          <td>40€</td>
        </tr>
        <tr>
          <td>SIC výplň 3 plôšky</td>
          <td>45€</td>
        </tr>
        <tr>
          <td>FK výplň 1 plôška front </td>
          <td>45€</td>
        </tr>
        <tr>
          <td>FK výplň 2 plôšky front</td>
          <td>50€</td>
        </tr>
        <tr>
          <td>FK výplň 3 plôšky front</td>
          <td>55€</td>
        </tr>
        <tr>
          <td>Rekonštrukcia zuba vo fronte, estetika</td>
          <td>80€</td>
        </tr>
        <tr>
          <td>FK výplň 1 plôška distál</td>
          <td>50€</td>
        </tr>
        <tr>
          <td>FK výplň 2 plôšky distál</td>
          <td>55€</td>
        </tr>
        <tr>
          <td>FK výplň 3 plôšky distál</td>
          <td>60€</td>
        </tr>
        <tr>
          <td>Rekonštrukcia zuba v distálnom úseku</td>
          <td>70€</td>
        </tr>
        <tr>
          <td>EverX post.</td>
          <td>20€</td>
        </tr>
        <tr>
          <td>Výplň mliečneho zuba / Twinky, SIC</td>
          <td>30€</td>
        </tr>
        <tr>
          <td>Lokálna fluoridácia u dieťaťa- obe zuboradia</td>
          <td>25€</td>
        </tr>
        <tr>
          <td>Odstránenie lokálneho dráždenia/ 1 zub</td>
          <td>13€</td>
        </tr>
        <tr>
          <td>Ošetrenie citlivých zubných plôšok/ 1 zub </td>
          <td>13€</td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th>Endodoncia</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Trepanácia 1 KK, extirpácia zub. drene</td>
          <td>35€</td>
        </tr>
        <tr>
          <td>Trepanácia mliečneho zuba</td>
          <td>35€</td>
        </tr>
        <tr>
          <td>
            Mech.- chem. preparácia + dočasné plnenie 1 KK Ca(OH)2 /+10 eur za
            každý kk
          </td>
          <td>45€</td>
        </tr>
        <tr>
          <td>Definitívne oš. 1 KK</td>
          <td>70€</td>
        </tr>
        <tr>
          <td>Definitívne oš. 2 KK</td>
          <td>100€</td>
        </tr>
        <tr>
          <td>Definitívne oš. 3 KK</td>
          <td>130€</td>
        </tr>
        <tr>
          <td>Definitívne oš. 4 KK</td>
          <td>145€</td>
        </tr>
        <tr>
          <td>Reendodoncia</td>
          <td>40€+def.oš.kk</td>
        </tr>
        <tr>
          <td>Kofferdam, Optradam</td>
          <td>13€</td>
        </tr>
        <tr>
          <td>MTA</td>
          <td>25€</td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th>Chirurgia</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Extrakcia mliečneho zuba</td>
          <td>25€</td>
        </tr>
        <tr>
          <td>Extrakcia jednokoreňového zuba</td>
          <td>32€</td>
        </tr>
        <tr>
          <td>Extrakcia viackoreňového zuba</td>
          <td>37€</td>
        </tr>
        <tr>
          <td>Neplánovaná chirurgická - komplikovaná extrakcia</td>
          <td>55€</td>
        </tr>
        <tr>
          <td>Ošetrenie a kontrola po extrakcii</td>
          <td>13€</td>
        </tr>
        <tr>
          <td>Dekapsulácia</td>
          <td>35€</td>
        </tr>
        <tr>
          <td>Sutúra extrakčnej rany</td>
          <td>10€</td>
        </tr>
        <tr>
          <td>Drén</td>
          <td>10€</td>
        </tr>
        <tr>
          <td>Sťažené prerezávanie zuba / preplach, dren</td>
          <td>15€</td>
        </tr>
        <tr>
          <td>Alvogyl / Gelatamp do extrakčnej rany</td>
          <td>13€</td>
        </tr>
        <tr>
          <td>Incízia</td>
          <td>10€</td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th>Protetika</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Komplexné stomatoprotetické vyšetrenie a návrh práce</td>
          <td>25€</td>
        </tr>
        <tr>
          <td>Sňatie fixnej náhrady / za každý člen</td>
          <td>18€</td>
        </tr>
        <tr>
          <td>Opätovné nasadenie fixnej náhrady / cementovanie</td>
          <td>33€</td>
        </tr>
        <tr>
          <td>Odtlačky </td>
          <td>45€</td>
        </tr>
        <tr>
          <td>
            Rekonštrukcia korunky - sklený čap + dostavba zuba/ distálny úsek
          </td>
          <td>130€</td>
        </tr>
        <tr>
          <td>
            Rekonštrukcia korunky - sklený čap + dostavba zuba/ frontálny úsek
          </td>
          <td>140€</td>
        </tr>
        <tr>
          <td>Sklený čap</td>
          <td>65€</td>
        </tr>
        <tr>
          <td>Dlaha proti bruxizmu</td>
          <td>80€</td>
        </tr>
        <tr>
          <td>Parodontálna dlaha </td>
          <td>od 130€</td>
        </tr>
        <tr>
          <td>Premostenie</td>
          <td>od 155€</td>
        </tr>
        <tr>
          <td>Parciálna snímateľná náhrada / flexibilná</td>
          <td>od 470€</td>
        </tr>
        <tr>
          <td>Totálna snímateľná náhrada</td>
          <td>od 470€</td>
        </tr>
        <tr>
          <td>Korunka zirkón </td>
          <td>od 270€</td>
        </tr>
        <tr>
          <td>Korunka keramika / medzičlen</td>
          <td>od 270€</td>
        </tr>
        <tr>
          <td>Fazeta celokeramická</td>
          <td>od 320€</td>
        </tr>
        <tr>
          <td>Wax Up / Mock Up / 1 člen</td>
          <td>40€</td>
        </tr>
        <tr>
          <td>Provizórna korunka - živicová </td>
          <td>od 50€</td>
        </tr>
        <tr>
          <td>Provizórna korunka zhotovená v laboratóriu </td>
          <td>od 80€</td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th>Dentálna hygiena</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Odstránenie zubného kameňa /1 sextant</td>
          <td>10€</td>
        </tr>
        <tr>
          <td>Dentálna hygiena </td>
          <td>45€</td>
        </tr>
        <tr>
          <td>Dentálna hygiena + Air Flow</td>
          <td>55€</td>
        </tr>
        <tr>
          <td>Polishing</td>
          <td>25€</td>
        </tr>
        <tr>
          <td>Bielenie zubov komplet</td>
          <td>210€</td>
        </tr>
        <tr>
          <td>Vnútorné bielenie mŕtvych zubov</td>
          <td>45€</td>
        </tr>
      </tbody>
    </Table>
  </Layout>
)

export default Pricelist
